import { debounce } from "@solid-primitives/scheduled";
import {
  Accessor,
  createEffect,
  createSignal,
  For,
  JSX,
  Match,
  onMount,
  Setter,
  Show,
  Switch,
} from "solid-js";
import { assetTickBlack, assetTickGrey, coinIcon } from "~/assets/assets";
import { HubbleEvent, webEventManager } from "~/data/events";
import { DiscountDetailsItem, VoucherProduct } from "~/server/types/brand";
import { Optional } from "~/types";
import { toIndianNumber, toRupees } from "~/utils/number";
import { isMobile } from "~/utils/platform";
import { Divider, DividerV2 } from "~/widgets/divider";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";
import { SwipeButton } from "~/widgets/swipe_button/swipe_button";
import { useModal } from "../modal";
import { ConfirmationDialog } from "./confirmation_dialog";
import { HubbleRewardsContent } from "./hubble_reward_content";

export type CtaState = "DEFAULT" | "LOADING" | "DISABLED";

type DenominationProps = {
  discountPercentage: number;
  selectedAmount: Accessor<Optional<number>>;
  setSelectedAmount: Setter<Optional<number>>;
  selectedVariantId: Accessor<Optional<string>>;
  setSelectedVariantId: Setter<Optional<string>>;
  initialErrorOnSimulate: Accessor<Optional<string>>;
  isSimulating: Accessor<boolean>;
  initiateOrder: () => void;
  ctaState: Accessor<CtaState>;
  useRewardCoins: Accessor<boolean | null>;
  setUseRewardCoins: Setter<boolean | null>;
  dryRunErrorMessage: Accessor<Optional<string>>;
  dueAmount: Accessor<Optional<number>>;
  redemptionAmount: Accessor<Optional<number>>;
  brand: VoucherProduct;
  isCoinsOnly: boolean;
  allowRewardCoins: boolean;
  showHubbleCoinRewards: boolean;
  hubbleCoinRewards: Accessor<DiscountDetailsItem | null>;
  coinsBalance: number;
  rewardDiscountAmount: Accessor<number>;
  buildHeader?: () => JSX.Element;
  buildInsufficientCoins?: () => JSX.Element;
  class?: string;
  isDarkTheme?: boolean;
  isRewardTypePostPaid: boolean;
  dividerClass?: string;
};

type DenominationsHeaderProps = {
  brandTitle: string;
  brandLogoUrl: string;
};

function DenominationsHeader(props: DenominationsHeaderProps) {
  return (
    <div class="inline-flex items-start justify-between self-stretch px-4 lg:px-6">
      <div class="inline-flex flex-col items-start justify-start">
        <div class=" text-h5 font-semibold leading-relaxed text-textDark lg:text-h3">
          Gift card value
        </div>
        <div class="text-medium text-baseSecondaryMedium">
          Your shopping amount
        </div>
      </div>
      <div class="relative h-11 w-11 rounded-[50%] lg:h-[52px] lg:w-[52px]">
        <HubbleImage
          src={props.brandLogoUrl}
          class="h-full w-full rounded-full border border-neutral-200 object-cover darkTheme:border-baseTertiaryDark "
          alt={`${props.brandTitle}'s logo url`}
        />
      </div>
    </div>
  );
}

type CustomAmountInputProps = {
  onInput: (event: any) => void;
  value: number | undefined;
  isInvalid: boolean;
};

function CustomAmountInput(props: CustomAmountInputProps) {
  const isInvalid = () => props.isInvalid;

  return (
    <div
      class="relative flex max-h-12 w-full items-center justify-start rounded-lg border-2 border-neutral-700 px-4 py-2 darkTheme:border-basePrimaryDark  darkTheme:bg-baseTertiaryDark dark:border-baseTertiaryLight lg:py-3.5"
      classList={{
        "border-red-700": isInvalid(),
      }}
    >
      <div class="text-base inline-flex w-full  text-[15px] font-semibold text-neutral-700 darkTheme:text-basePrimaryDark dark:text-baseTertiaryLight">
        <span class="ml-1 text-[15px]">₹</span>
        <input
          id="custom-amount-input"
          placeholder="Enter amount"
          type="number"
          class="grow-1 ml-1 border-0 bg-transparent text-f16  placeholder-gray-400 placeholder:text-f16 focus:border-transparent focus:outline-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          value={props.value}
          onInput={props.onInput}
        />
      </div>
      <div class="absolute right-[6px] top-[6px] block h-[6px] w-[9px]">
        <img src={assetTickBlack} width={9} height={6} alt="tick" />
      </div>
    </div>
  );
}

export function DenominationsGrid(props: DenominationProps) {
  const isSimulating = () => props.isSimulating();
  const ctaState = () => props.ctaState();
  const selectedAmount = () => props.selectedAmount();
  const errorMessage = () => props.dryRunErrorMessage();
  const setSelectedAmount = props.setSelectedAmount;

  const setSelectedVariantId = props.setSelectedVariantId;
  const { setIsModalOn, updateModalContentGetter, setOuterFrameClass } =
    useModal()!;

  function enoughCoinsAvailable() {
    if (!props.isCoinsOnly) {
      return true;
    }

    return props.coinsBalance >= (props.dueAmount() ?? 0);
  }

  const isFixedDenominationBrand =
    props.brand.amountConditions.denominations != null;

  const customAmountDefaultDenominationsList = [500, 1000, 2000, 5000].filter(
    (denomination) =>
      denomination >= props.brand.amountConditions.minAmount &&
      denomination <= props.brand.amountConditions.maxAmount
  );

  const customAmountInputDebounce = debounce((customAmount: number) => {
    if (isNaN(customAmount)) return;
    setSelectedAmount(customAmount);
  }, 300);

  const isVariantBrand = props.brand.variantDetailedResponse != null;

  let denominationList =
    props.brand.amountConditions.denominations ??
    customAmountDefaultDenominationsList;

  if (props.isCoinsOnly) {
    denominationList = filterOutUnnecessaryDenominations({
      amount: props.coinsBalance,
      denominations: denominationList,
    });
  }

  const columnsCount = denominationList.length <= 4 ? 2 : 3;
  const isCustomDenominationAllowed =
    !isFixedDenominationBrand && !isVariantBrand;

  // if the default amount is present and not avaliable in the denominationList then show it in the custom input field
  const defaultAmount = props.brand.amountConditions.defaultAmount;
  const defaultAmountInCustomInput =
    isCustomDenominationAllowed && !denominationList.includes(defaultAmount)
      ? defaultAmount
      : null;

  function filterOutUnnecessaryDenominations(props: {
    amount: number;
    denominations: number[];
  }) {
    if (props.amount < 500) {
      return props.denominations;
    }

    // filter out denominations greater than available coins
    return props.denominations.filter(
      (denomination) => denomination <= props.amount
    );
  }

  onMount(() => {
    if (defaultAmountInCustomInput !== null) {
      setCustomSelected(true);
      document.getElementById("custom-amount-input")?.focus();
      setSelectedAmount(defaultAmountInCustomInput);
    }
  });

  const [customSelected, setCustomSelected] = createSignal<boolean>(
    isCustomDenominationAllowed &&
      customAmountDefaultDenominationsList.length == 0
  );

  let denominationBorderClass =
    // text-neutral-700
    "text-textDark group darkTheme:border-baseLight relative flex max-h-10 dark:bg-basePrimaryDark darkTheme:hover:border-[#7c7c7c] darkTheme:bg-baseTertiaryDark  dark:text-baseTertiaryLight  cursor-pointer flex-row items-center justify-center rounded-lg px-2.5 py-2 hover:border-baseSecondaryMedium   dark:hover:border-baseTertiaryLight lg:h-[48px] lg:py-3.5";

  return (
    <div
      class={`${props.class} shadow-sm flex h-fit w-full flex-col items-center justify-start gap-4 rounded-2xl border border-baseTertiaryDark bg-baseTertiaryLight pb-4 dark:border-basePrimaryMedium dark:bg-baseDark lg:pb-6 `}
    >
      {props.buildHeader?.() ?? (
        <>
          <div class="mt-1 "></div>
          <DenominationsHeader
            brandTitle={props.brand.voucherProductMetadata.title}
            brandLogoUrl={props.brand.voucherProductMetadata.logoUrl}
          />
        </>
      )}
      <div class="w-full px-4 lg:px-6">
        <Switch>
          <Match when={isVariantBrand}>
            <div
              class="grid-rows-auto grid h-fit w-full  gap-3"
              style={{
                "grid-template-columns": `repeat(${1}, 1fr)`,
              }}
            >
              {buildVariants()}
            </div>
          </Match>
          <Match when={true}>
            <div
              class="grid-rows-auto grid h-fit w-full  gap-3"
              style={{
                "grid-template-columns": `repeat(${columnsCount}, 1fr)`,
              }}
            >
              {buildDenominations()}
              <Show when={isCustomDenominationAllowed}>
                {buildCustomBlock()}
              </Show>
            </div>
            <Spacer height={12} />
            <Show when={isCustomDenominationAllowed && customSelected()}>
              <CustomAmountInput
                isInvalid={errorMessage() != null}
                onInput={(event) => {
                  customAmountInputDebounce(parseInt(event.target.value!));
                }}
                value={defaultAmountInCustomInput ?? undefined}
              />
              <Spacer height={4} />
            </Show>
            <Show when={errorMessage() != null}>
              <div class="text-mediumBold text-red-700">{errorMessage()}</div>
            </Show>
          </Match>
        </Switch>
      </div>
      <div class={`w-full ${props.dividerClass}`}>
        <DividerV2 class="bg-[#E5E5E5]  darkTheme:bg-baseTertiaryDark dark:bg-basePrimaryMedium" />
      </div>
      <div class="flex w-full flex-col  gap-4 px-4 lg:px-6">
        <MakePaymentCta />
      </div>
    </div>
  );

  function buildVariants() {
    return (
      <For each={props.brand.variantDetailedResponse}>
        {(variant, index) => {
          let amount = variant.amount;
          let vairantId = variant.variantId;
          let [isSelectedAmount, setIsSelectedAmount] = createSignal<boolean>(
            selectedAmount() == amount
          );

          createEffect(() => {
            setIsSelectedAmount(selectedAmount() == amount);
          });

          return (
            <div
              class={`${denominationBorderClass}`}
              classList={{
                "border-neutral-700 dark:border-baseTertiaryLight":
                  isSelectedAmount() && errorMessage() === null,
                "border-neutral-200": !isSelectedAmount(),
                "border-2": isSelectedAmount(),
                border: !isSelectedAmount(),
                "bg-slate-100": isSelectedAmount(),
                "bg-baseTertiaryLight": !isSelectedAmount(),
                "border-errorDark hover:border-errorDark":
                  isSelectedAmount() && errorMessage() != null,
              }}
              onClick={() => {
                setSelectedVariantId(vairantId);
                setCustomSelected(false);
                setSelectedAmount(amount);
              }}
            >
              <div class="w-full text-bold">
                <span>{variant.text}</span>
              </div>
              {/*TODO - amount should be lighter when variants are on*/}
              <div class="text-bold">
                <span>₹{amount}</span>
              </div>
              <div
                class="absolute right-[9px] top-[1px] h-[6px] w-[9px]"
                classList={{
                  "group-hover:block": !isSelectedAmount(),
                  block: isSelectedAmount(),
                  hidden: !isSelectedAmount(),
                  [getCheckColor({
                    isSelected: isSelectedAmount(),
                  })]: true,
                }}
              >
                {checkIcon()}
              </div>
            </div>
          );
        }}
      </For>
    );
  }

  function buildCustomBlock() {
    return (
      <div
        class={`${denominationBorderClass} border border-neutral-200 darkTheme:border-baseTertiaryLight dark:border-baseDark  `}
        classList={{
          hidden: customSelected(),
        }}
        onClick={() => {
          setCustomSelected(true);
          document.getElementById("custom-amount-input")?.focus();
          setSelectedAmount(null);
        }}
      >
        <div class="font-semibold">
          <span>Custom</span>
        </div>
        <div
          class="absolute right-[9px] top-[1px] h-[6px] w-[9px]"
          classList={{
            "group-hover:block": !customSelected(),
            block: customSelected(),
            hidden: !customSelected(),
            [getCheckColor({
              isSelected: customSelected(),
            })]: true,
          }}
        >
          {checkIcon()}
        </div>
      </div>
    );
  }

  function buildDenominations() {
    return (
      <For each={denominationList}>
        {(amount, index) => {
          let [isSelectedAmount, setIsSelectedAmount] = createSignal<boolean>(
            selectedAmount() == amount
          );

          createEffect(() => {
            setIsSelectedAmount(
              selectedAmount() == amount && !customSelected()
            );
          });

          return (
            <div
              class={`${denominationBorderClass} `}
              classList={{
                "!border-basePrimaryDark dark:!border-baseTertiaryLight":
                  isSelectedAmount() && errorMessage() === null,
                "light:border-neutral-200 dark:border-baseDark ":
                  !isSelectedAmount(),
                "border-2": isSelectedAmount(),
                border: !isSelectedAmount(),
                "bg-baseTertiaryLight": true,
                "border-errorDark hover:border-errorDark":
                  isSelectedAmount() && errorMessage() != null,
              }}
              onClick={() => {
                setCustomSelected(false);
                setSelectedAmount(amount);
              }}
            >
              <div class="text-bold">
                <span>₹{amount}</span>
              </div>
              <div
                class="absolute right-[9px] top-[1px] h-[6px] w-[9px]"
                classList={{
                  "group-hover:block": !isSelectedAmount(),
                  block: isSelectedAmount(),
                  hidden: !isSelectedAmount(),
                  [getCheckColor({
                    isSelected: isSelectedAmount(),
                  })]: true,
                }}
              >
                {checkIcon()}
              </div>
            </div>
          );
        }}
      </For>
    );
  }

  function getCheckColor(pr: { isSelected: boolean }) {
    if (pr.isSelected) {
      if (props.isDarkTheme) {
        return "stroke-white";
      }
      return "stroke-basePrimaryDark darkTheme:stroke-white";
    } else {
      if (props.isDarkTheme) {
        return "stroke-[#7C7C7C]";
      }
      return "darkTheme:stroke-[#7C7C7C] stroke-[#ABABAB]";
    }
  }

  function checkIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M4 11L6.55556 13.7778L12.3333 8"
          stroke="inherit"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }

  function MakePaymentCta() {
    return (
      <>
        <Show when={props.initialErrorOnSimulate()}>
          <div class="text-mediumBold text-red-700">
            {props.initialErrorOnSimulate()}
          </div>
        </Show>
        <Show
          when={
            props.allowRewardCoins &&
            props.coinsBalance > 0 &&
            !props.initialErrorOnSimulate()
          }
        >
          <div class="flex w-full justify-between gap-2">
            <div class="flex items-center gap-1">
              <img src={coinIcon} class="h-[21px] w-[21px] pr-1" />
              <Show
                when={props.useRewardCoins()}
                fallback={
                  <>
                    <p class="text-mediumBold text-textDark lg:text-bold">
                      Use rewards ·{" "}
                    </p>
                    <p class="text-mediumBold text-successDark lg:text-bold">
                      Get ₹
                      {props.coinsBalance <= (props.dueAmount() ?? 0)
                        ? props.coinsBalance
                        : props.dueAmount()}{" "}
                      off
                    </p>
                  </>
                }
              >
                <p class="text-mediumBold text-successDark lg:text-bold">
                  ₹{props.rewardDiscountAmount()} off
                </p>
                <p class="text-medium text-textNormal lg:text-normal">
                  using {props.rewardDiscountAmount()} rewards
                </p>
              </Show>
            </div>
            <label class="inline-flex cursor-pointer items-center">
              <input
                type="checkbox"
                checked={props.useRewardCoins() ?? false}
                onChange={() => {
                  props.setUseRewardCoins((prev) => !prev);
                }}
                class="peer sr-only"
              />
              <div
                class={`peer relative h-5 w-9 rounded-full border border-basePrimaryDark after:absolute after:start-[2px] after:top-[1px] after:h-4 after:w-4 after:rounded-full after:bg-basePrimaryDark after:transition-all after:content-[''] ${props.useRewardCoins() ? "peer-checked:bg-basePrimaryDark peer-checked:after:translate-x-full peer-checked:after:border-baseTertiaryLight peer-checked:after:bg-baseTertiaryLight peer-focus:outline-none rtl:peer-checked:after:-translate-x-full" : ""}`}
              ></div>
            </label>
          </div>
          <Divider />
        </Show>
        <Show
          when={props.showHubbleCoinRewards && !props.initialErrorOnSimulate()}
        >
          <div class="flex flex-col gap-1">
            <p class="flex items-center gap-1 text-mediumBold text-textDark lg:text-bold">
              Earning {props.hubbleCoinRewards()?.percentage}% rewards{" "}
              <PhosphorIcon
                name="info"
                fontSize={15}
                class="cursor-pointer text-baseSecondaryLight"
                size="bold"
                onClick={() => {
                  updateModalContentGetter(() => {
                    return <HubbleRewardsContent />;
                  });

                  setIsModalOn(true);
                }}
              />
            </p>
            <div class="flex items-center gap-1">
              <img src={coinIcon} class="h-[25px] w-[25px]" />{" "}
              <p class="text-h3 text-rewardsYellow">
                {props.hubbleCoinRewards()?.amount! % 1 === 0
                  ? props.hubbleCoinRewards()?.amount
                  : props.hubbleCoinRewards()?.amount.toFixed(2)}
              </p>
            </div>
          </div>
          <Show when={props.isCoinsOnly || !props.isRewardTypePostPaid}>
            <Divider />
          </Show>
        </Show>
        <div class="flex flex-col gap-4 self-stretch ">
          <Switch>
            <Match when={props.isCoinsOnly}>
              <Switch>
                <Match when={enoughCoinsAvailable()}>
                  <Switch>
                    <Match when={props.dueAmount() == props.redemptionAmount()}>
                      <div
                        class="flex w-full flex-row items-center justify-center  text-[15px] font-semibold dark:text-textNormal"
                        classList={{
                          "opacity-40":
                            isSimulating() ||
                            selectedAmount() == null ||
                            errorMessage() != null,
                        }}
                      >
                        <span>Get using</span>
                        <Spacer width={6} />
                        <img src={coinIcon} class="h-[21px] w-[21px]" />
                        <Spacer width={4} />
                        <span class="text-[#E89901]">
                          {toIndianNumber(props.dueAmount() ?? 0)}
                        </span>
                      </div>
                    </Match>
                    <Match when={true}>
                      <DueAmount isCoinsOnly={true} />
                    </Match>
                  </Switch>
                </Match>
                <Match when={true}>
                  {props.buildInsufficientCoins?.() ?? (
                    <InsufficientCoins
                      coinsBalance={props.coinsBalance}
                      requiredBalance={props.dueAmount}
                    />
                  )}
                </Match>
              </Switch>
            </Match>
            <Match
              when={
                !props.isRewardTypePostPaid && !props.initialErrorOnSimulate()
              }
            >
              <DueAmount />
            </Match>
          </Switch>
          <div class="flex flex-col items-start justify-start gap-3 self-stretch ">
            <Switch fallback={buildGetThisCardButton(onClickGetThisCard)}>
              <Match when={props.isCoinsOnly}>
                {buildCoinsOnlyPaymentCta()}
              </Match>
              <Match when={props.dueAmount() === 0}>
                {buildGetThisCardButton(() => {
                  updateModalContentGetter(() => {
                    return (
                      <ConfirmationDialog
                        title={`${toRupees(selectedAmount() ?? 0)} ${props.brand.voucherProductMetadata.title} card`}
                        message={
                          <div class="flex flex-row items-center text-normal text-textDark ">
                            {`You will be using`}
                            <img
                              src={coinIcon}
                              class="ml-[6px] mr-[4px] h-5 w-5"
                            />
                            <span class="font-semibold text-[#E89901]">
                              {props.rewardDiscountAmount()}
                            </span>
                          </div>
                        }
                        logoUrl={props.brand.voucherProductMetadata.logoUrl}
                        onConfirm={() => {
                          setIsModalOn(false);
                          setOuterFrameClass("");
                          onClickGetThisCard();
                        }}
                        onCancel={() => {
                          setIsModalOn(false);
                          setOuterFrameClass("");
                        }}
                      />
                    );
                  });

                  setOuterFrameClass("lg:w-[416px]");
                  setIsModalOn(true);
                })}
              </Match>
            </Switch>
            <Show when={!props.isCoinsOnly}>
              <div class="inline-flex items-center justify-center gap-1 self-stretch">
                <div class=" text-medium text-textDark">
                  Safe & secure payment
                </div>
                <img
                  class="h-4 w-20"
                  src={
                    "https://gullak-assets.s3.ap-south-1.amazonaws.com/icons/razorpay-trust-marker.png"
                  }
                  alt="Secure payments"
                />
              </div>
            </Show>
          </div>
        </div>
      </>
    );
  }

  function buildCoinsOnlyPaymentCta() {
    return (
      <>
        <div class="w-full md:hidden">
          <Switch>
            <Match when={isPaymentCtaEnabled()}>
              <SwipeButton
                isLoading={() => {
                  return ctaState() === "LOADING";
                }}
                isEnabled={isPaymentCtaEnabled}
                onSwipe={onClickGetThisCard}
                loaderColor={props.isDarkTheme ? "#000" : "#fff"}
                isDarkTheme={props.isDarkTheme}
              />
            </Match>
            <Match when={true}>{buildGetThisCardButton(() => {})}</Match>
          </Switch>
        </div>
        <div class="hidden w-full md:flex">
          {buildGetThisCardButton(() => {
            updateModalContentGetter(() => {
              return (
                <ConfirmationDialog
                  title={`${toRupees(selectedAmount() ?? 0)} ${props.brand.voucherProductMetadata.title} card`}
                  message={
                    <div class="flex flex-row items-center text-normal text-textDark ">
                      {`You will be using`}
                      <img src={coinIcon} class="ml-[6px] mr-[4px] h-5 w-5" />
                      <span class="font-semibold text-[#E89901]">
                        {toIndianNumber(props.dueAmount() ?? 0)}
                      </span>
                    </div>
                  }
                  logoUrl={props.brand.voucherProductMetadata.logoUrl}
                  onConfirm={() => {
                    setIsModalOn(false);
                    setOuterFrameClass("");
                    onClickGetThisCard();
                  }}
                  onCancel={() => {
                    setIsModalOn(false);
                    setOuterFrameClass("");
                  }}
                />
              );
            });

            setOuterFrameClass("lg:w-[416px]");
            setIsModalOn(true);
          })}
        </div>
      </>
    );
  }

  function isPaymentCtaEnabled() {
    return (
      ctaState() != "DISABLED" &&
      selectedAmount() != null &&
      errorMessage() == null &&
      enoughCoinsAvailable()
    );
  }

  function buildGetThisCardButton(onClick: () => void) {
    return (
      <Switch>
        <Match when={ctaState() == "LOADING"}>
          <div class="button-filled inline-flex h-12 w-full cursor-pointer items-center justify-center gap-2.5 self-stretch rounded-3xl bg-neutral-800 px-4 py-2.5 opacity-40">
            <div class="text-mediumBold text-baseTertiaryLight">
              <DottedLoader color="#fff" />
            </div>
          </div>
        </Match>
        <Match when={true}>{buildCta()}</Match>
      </Switch>
    );
    function buildCta() {
      return (
        <div
          class={`button-filled inline-flex h-12 w-full  items-center justify-between gap-2.5 self-stretch rounded-3xl bg-basePrimaryDark px-6 py-2.5 ${isPaymentCtaEnabled() ? "cursor-pointer" : "cursor-not-allowed"}`}
          classList={{
            "opacity-40 dark:bg-basePrimaryDark  dark:opacity-100":
              !isPaymentCtaEnabled(),
          }}
          onClick={() => {
            if (!isPaymentCtaEnabled()) {
              return;
            }
            if (ctaState() == "DEFAULT") {
              onClick();
            }
          }}
        >
          {ctaContent()}
        </div>
      );
    }
  }

  function ctaContent() {
    return (
      <div
        class={`flex w-full text-baseTertiaryLight  ${props.isRewardTypePostPaid ? "justify-between" : "justify-center"}`}
        classList={{
          "dark:opacity-30": !isPaymentCtaEnabled(),
        }}
      >
        <Show when={props.isRewardTypePostPaid}>
          <div class="flex ">
            <div class="text-mediumBold ">Pay ₹{props.dueAmount()}</div>
            <Show when={props.dueAmount() != props.selectedAmount()}>
              <div class="pl-1 text-medium text-textNormal line-through">
                ₹{props.selectedAmount()}
              </div>
            </Show>
          </div>
        </Show>
        <div class="flex items-center justify-center">
          <div class="text-mediumBold ">Get this card</div>
          <Show when={props.isRewardTypePostPaid}>
            <PhosphorIcon
              name="caret-right"
              size="bold"
              fontSize={13}
              class="px-2 "
            />
          </Show>
        </div>
      </div>
    );
  }

  function onClickGetThisCard() {
    webEventManager.sendEvent(HubbleEvent.CLICK_GET_THIS_CARD, {
      amount: selectedAmount(),
      brandName: props.brand.name,
    });

    if (props.isCoinsOnly && !isMobile()) {
      // show headsup
    }

    props.initiateOrder();
  }

  function DueAmount({ isCoinsOnly = false }: { isCoinsOnly?: boolean }) {
    return (
      <div class="inline-flex items-end justify-between self-stretch">
        <div class="inline-flex flex-col items-start justify-start">
          <div class="text-mediumBold text-textDark dark:text-textNormal lg:text-bold">
            {isCoinsOnly ? "Get using" : "You pay"}
          </div>
          <div
            class="inline-flex items-center justify-start gap-1"
            classList={{
              "opacity-40":
                isSimulating() ||
                selectedAmount() == null ||
                errorMessage() != null,
            }}
          >
            <Show when={isCoinsOnly}>
              <img src={coinIcon} class="h-5 w-5" />
            </Show>
            <div
              class={`text-h5 lg:text-h3`}
              classList={{
                "text-textDark": props.discountPercentage === 0,
                "text-[#E89901]": isCoinsOnly,
                "text-successDark": !isCoinsOnly,
              }}
            >
              {isCoinsOnly ? "" : "₹"}
              <span class="anim-due-amount">{props.dueAmount()}</span>
            </div>
            <Show when={props.discountPercentage !== 0}>
              <div class="relative h-7 w-10">
                <div class="text-base absolute left-0 top-[4px] text-textNormal line-through">
                  {isCoinsOnly ? "" : "₹"}
                  {props.redemptionAmount()}
                </div>
              </div>
            </Show>
          </div>
        </div>
        <Show when={props.discountPercentage !== 0}>
          <div class="inline-flex h-4 flex-col items-end justify-end gap-2.5 pb-1">
            <div class="inline-flex items-end justify-end gap-1 text-successDark">
              <div class="text-mediumBold lg:text-bold">Instant</div>
              <div class="text-mediumBold lg:text-bold">
                {props.discountPercentage}% off
              </div>
            </div>
          </div>
        </Show>
      </div>
    );
  }

  type InsufficientCoinsProps = {
    coinsBalance: number;
    requiredBalance: Accessor<Optional<number>>;
  };

  function InsufficientCoins(props: InsufficientCoinsProps) {
    return (
      <div
        class="flex flex-row rounded-xl border border-errorDark bg-gradient-to-r from-[#F8E6E3] px-3 py-2 transition-opacity"
        style={{
          background:
            "linear-gradient(90deg, rgba(187, 45, 19, 0.12) 2.8%, rgba(187, 45, 19, 0.00) 82.89%)",
        }}
        classList={{
          "opacity-40":
            isSimulating() ||
            selectedAmount() == null ||
            (errorMessage() != null && !enoughCoinsAvailable()),
        }}
      >
        <PhosphorIcon
          name="warning-circle"
          fontSize={24}
          class=" content-center text-errorDark"
        />
        <Spacer width={8} />
        <div class="flex flex-col">
          <span class="text-subtitleSmall text-errorDark">
            {toIndianNumber(props.requiredBalance() ?? 0)} rewards needed to get
            this card
          </span>
          <Spacer height={2} />
          <div class="flex flex-row text-small leading-4 dark:text-baseSecondaryMedium ">
            {`You have:`}
            <Spacer width={2} />
            <img src={coinIcon} class="h-4 w-4"></img>
            <Spacer width={2} />
            {toIndianNumber(props.coinsBalance)}
          </div>
        </div>
      </div>
    );
  }
}
